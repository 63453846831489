import React from 'react';

export const Footer = () => {
  return (
    <footer>
      &copy; {new Date ().getFullYear ()} -
      {' '}
      <a href="https://johanskylservice.se">Johans Kylservice</a>
      {' '}
      - Kylservice i världsklass
    </footer>
  );
};
