import React from 'react';

export const ContactBox = () => {
  return (
    <div className="textbox">
      <h2>Kontakt</h2>
      <p>
        Johans Kylservice
        <br />
        Staviksvägen 59
        <br />
        437 92 Lindome
      </p>
      <p>
        Telefon: <a href="tel:+46735850001">0735 - 85 00 01</a>
      </p>
      <p>
        E-post:{' '}
        <a href="mailto:johan@johanskylservice.se">johan@johanskylservice.se</a>
      </p>
    </div>
  );
};
