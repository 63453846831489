import React from 'react';

export const WelcomeBox = () => {
  return (
    <div className="textbox">
      <h2>Hej och välkommen!</h2>
      <p>
        Johans Kylservice är Din lokala reparatör och installatör av kyl- och frysanläggningar, värmepumpar, luftkonditioneringar mm. Vi arbetar främst i Göteborg med omnejd.
      </p>
    </div>
  );
};
