import React from 'react';
import './App.css';
import {Header, WelcomeBox, ContactBox, Footer} from './components';

function App () {
  return (
    <div className="App">
      <div className="content">

        <Header />

        <div className="box-container">
          <WelcomeBox />
          <ContactBox />
        </div>
        
        <Footer />
        
      </div>
    </div>
  );
}

export default App;
