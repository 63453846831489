import React from 'react'
import Logo from '../images/logo_mellan.png'

export const Header = () => {
    return (
        <header>
          <h1 hidden>Johans Kylservice</h1>
          <img src={Logo} alt="Johans Kylservice - logotyp" />
        </header>
    )
}
